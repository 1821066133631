import React, { Component } from "react";

import logo from './logo.svg';
import './App.css';
// import song_shoud from "../src/assets/audios/station_shoud.mp3";
// import song_italy from "../src/assets/audios/station_italynel.mp3";
// import song_litle from "../src/assets/audios/station_little.mp3";
import song_pleine from "../src/assets/audios/statipn_pleine.mp3";

import arab_little_italy from './assets/newAudios/little_italy_arab.m4a';
import little_italy_station from './assets/newAudios/little_italy_station_.m4a';
import street_food_arab from './assets/newAudios/street_food_arab.m4a';
import street_food from './assets/newAudios/street_food_st.m4a';
import couptoir_shoud_ar from './assets/newAudios/couptoir_shoud_ar.m4a';
import couptoir_shoud from './assets/newAudios/couptoir_shoud.m4a';
import all_station_una from './assets/newAudios/all_stations_are_una.m4a';


import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from "firebase/database";

import Details from "./details";
import config from "../src/config/fbConfig";
import loader from "../src/logo/loader.gif";

class App extends Component {
    constructor(props) {
            super(props);
            this.state = {
                details: [],
                title: "",
                today: "",
                playing: false,
                setPlaying: false,
                lastTimePlay: new Date(),
                shoud: false,
                italy: false,
                street: false,
                unav: false,
            };
        }
        //const[audio] = useState(new Audio());
        //const[playing, setPlaying] = useState(false);


    componentDidMount() {
        var audio_italy = new Audio(little_italy_station);
        var audio_italy_arab = new Audio(arab_little_italy);

        var street_food = new Audio(street_food);
        var street_food_arab = new Audio(street_food_arab);

        var audio_shoud = new Audio(couptoir_shoud);
        var audio_shoud_arab = new Audio(couptoir_shoud_ar);

        var audio_pleine = new Audio(song_pleine);
        var audio_plan = new Audio(all_station_una);
        //./assets/audios/warning.mp3
        console.log("audio", audio_shoud);
        audio_italy.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), italy: !this.state.italy }));
        street_food.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), street: !this.state.street }));
        audio_shoud.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), shoud: !this.state.shoud }));
        audio_pleine.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), unav: !this.state.unav }));
        audio_italy_arab.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), italy: !this.state.italy }));
        street_food_arab.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), street: !this.state.street }));
        audio_shoud_arab.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), shoud: !this.state.shoud }));
        audio_plan.addEventListener('ended', () => this.setState({ playing: false, lastTimePlay: new Date(), unav: !this.state.unav }));


        //this.state.playing ? audio_italy.play() : audio_italy.pause();
        console.log('playing', this.state.playing);

        //initializeApp(config);
        const app = initializeApp(config);

        const database = getDatabase(app);
        return onValue(ref(database, '/'), (snapshot) => {
            let details = snapshot.val();

            this.setState({
                details: details,
            });
            if (details && details.Places && details.Places && details.Places.restaurants && details.Places.restaurants[2].Queue >= 15) {

                // if (details && details.stationsCCI.length !== 0) {
                //     if (details.stations[1].people > 2 && details.stations[2].people < 4 && !this.state.playing && new Date() - this.state.lastTimePlay > 5000) {
                //         console.log("first_condition");
                //         if (this.state.shoud) {
                //             audio_shoud.play();
                //         } else {
                //             audio_shoud_arab.play();
                //         }
                //         this.setState({ playing: true });
                //     } else if (details.stations[1].people < 3 && details.stations[2].people > 3 && !this.state.playing && new Date() - this.state.lastTimePlay > 5000) {
                //         if (this.state.italy) {
                //             audio_italy.play();
                //         } else {
                //             audio_italy_arab.play();
                //         }

                //         this.setState({ playing: true });
                //     } else if (details.stations[1].people > 2 && details.stations[2].people > 3 && details.stations[3].people < 6 && !this.state.playing && new Date() - this.state.lastTimePlay > 5000) {

                //         if (this.state.street) {
                //             street_food.play();
                //         } else {
                //             street_food_arab.play();
                //         }
                //         this.setState({ playing: true });
                //     } else if (details.stations[1].people > 3 && details.stations[2].people > 4 && details.stations[3].people > 6 && !this.state.playing && new Date() - this.state.lastTimePlay > 5000) {
                //         if (this.state.unav) {
                //             audio_pleine.play();
                //         } else {
                //             audio_plan.play();
                //         }
                //         this.setState({ playing: true });
                //     }
                // }
            }
            console.log(this.state.title);
            this.checkTitle();
            console.log(this.getNow());
            let time = this.getNow();

            if (time >= "04:00:00" && time <= "16:00:00") {
                this.setState({
                    title: "lunch",
                });
                console.log("yes")
            } else {
                this.setState({
                    title: "dinner",
                });
                console.log("No")
            }

        });

        let today = this.getToday();
        this.setState({
            today,
        });
    }

    getNow = () => {
        const today = new Date();
        let time =
            today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return time;
    };
    checkTitle = () => {

    };
    getToday() {
        let days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        let today = days[new Date().getDay()];

        return today;
    }
    render() {
        const { details, title } = this.state;

        let Today = this.getToday();
        let today = Today.toLowerCase();
        //console.log(details && details.daysCCI && details.daysCCI[today]);
        //console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhh");
        let stationsCCI =
            details &&
            details.daysCCI &&
            details.daysCCI[today] &&
            details.daysCCI[today].station;
        console.log("stationsCCI", stationsCCI);
        let stationDetails = [];
        let newDetails = stationDetails.concat(stationsCCI);
        let capacities = [5, 5, 5];
        console.log("newDetails", newDetails)

        return ( <
            div className = "container" > {
                newDetails &&
                newDetails.length > 0 &&
                newDetails[0] != undefined &&
                newDetails.reverse().map((stationsCCI, i) => {
                    console.log("capacities", capacities[i]);
                    return ( <
                        Details title = { title }
                        stationNumber = { 3 - i }
                        details = { stationsCCI }
                        key = { 3 - i }
                        peopleDetails = {
                            details.stationsCCI.length != 0 ? details.stationsCCI[3 - i] : 0
                        }
                        capacity = { capacities[2 - i] }


                        />
                    );
                })
            } {
                newDetails && newDetails[0] == undefined && ( < div className = "no-data" >

                    <
                    img src = { loader }
                    /> < /
                    div >
                )
            }

            <
            /div>

        );
    }
}
export default App;
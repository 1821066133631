import React from "react";
import logo from "../src/logo/humanlogo.svg";
import bluedove_logo from "../src/logo/bluedOVE.png";
import um6p_logo from "../src/logo/um6p_logo.png";

const Details = ({ title, details, stationNumber, peopleDetails, capacity }) => {
    let stationDetails = details && title && details[title];
    /*let no = capacity - Number(peopleDetails.people);
    let classSelection = (no) => {
      switch (no) {
        case 0:
          return "color-0";
          break;
        case 1:
          return "color-1";
          break;
        case 2:
          return "color-2";
          break;
        case 3:
          return "color-3";
          break;
        case 4:
          return "color-4";
          break;
        default:
          return "color-0";
      }
    };*/
    let classSelection = (capacity, peopleDetails) => {
        if (Number(peopleDetails.people) <= 0.4 * capacity) {
            return "color-4";
        } else if (Number(peopleDetails.people) > 0.4 * capacity && Number(peopleDetails.people) <= 0.7 * capacity) {
            return "color-3";
        } else if (Number(peopleDetails.people) > 0.7 * capacity && Number(peopleDetails.people) < capacity) {
            return "color-1";
        } else if (Number(peopleDetails.people) >= capacity) {
            return "color-0";
        } else {
            return "color-4";
        }
    };
    let name = classSelection(capacity, peopleDetails);

    return ( <
        div className = { `row ${name}` } >
        <
        header > { stationNumber == 3 ? "Street Food" : stationNumber == 1 ? "Comptoir Chaud" : stationNumber == 2 ? "Little Italy" : null } < /header> {
        stationDetails &&
        stationDetails.map((details, i) => {
            return ( <
                div className = "section"
                key = { i } > {
                    i == 0 && ( <
                        React.Fragment >
                        <
                        div className = "box" >
                        <
                        div className = { `box-left ${name}` } >
                        <
                        img src = { logo }
                        id = "logo"
                        alt = "logo" / >
                        <
                        /div> <
                        div className = { `box-right ${name}` } > {
                            peopleDetails &&
                            peopleDetails.people >= 0 &&
                            peopleDetails.people < capacity ?
                            Number(peopleDetails.people) : "Stop"
                        } <
                        /div> < /
                        div > <
                        div className = { `gap ${name}` } > < /div> < /
                        React.Fragment >
                    )
                } {
                    (details.imageUrl != null || details.imageUrl != "") && ( <
                        img src = { details.imageUrl }
                        className = "dish-image"
                        alt = { details.imageName }
                        />
                    )
                } <
                p className = "footer" > { details.title } < /p> < /
                div >
            );
        })
    } {
        stationNumber == 3 ? < img className = "bluedove_logo"
        src = { bluedove_logo }
        /> :stationNumber==1? <img className="bluedove_logo"  src={um6p_logo} / >: null
    }

    <
    /div>
);
};

export default Details;
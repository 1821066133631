const config = {
    apiKey: "AIzaSyBAV6lzdrkb-0ABVX6wyatYJERgJfBTLa8",
  authDomain: "no-waiting-um6p.firebaseapp.com",
  databaseURL: "https://no-waiting-um6p.firebaseio.com",
  projectId: "no-waiting-um6p",
  storageBucket: "no-waiting-um6p.appspot.com",
  messagingSenderId: "500583023100",
  appId: "1:500583023100:web:4ac75b0cb9677ab9b93279",
  measurementId: "G-9KG2Q7GZH4"
};

export default config;
